import React from "react";
import { filterSingleValue } from "../../../api_calls/TableauVisuals";
import SelectBox from "../Selectors/SelectBox";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { dbFormatDate, formatDate, storeListOptionsForTableauFilter } from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import { checkUserRole } from "../../../api_calls/userManagement";
import MultiSelect from "../Selectors/MultiSelect";
import { filterClips } from "../../../api_calls/clipRequests";
import { loadClips, resetClipsData } from "../../../api_calls/clipRequests";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import { clearfilters } from "../../../api_calls/campaignRequests";

const ClipNameFilter = ({ vizId, fieldName, usedFor }) => {
    const clipDetails = useSelector((state) => state.userSelections);
    // console.log("clipDetails: ", clipDetails)
    const [changesMade, setChangesMade] = React.useState(false);
    const [clipSelection, setClipSelection] = React.useState([]);
    const [clipNameOptionSelected, setClipNameOptionsSelected] = React.useState([]);

    return (
        <MultiSelect
            enableFullWidth={false}
            label={"Clip Name"}
            options={clipDetails.clip_names_list}
            defaultVal={clipNameOptionSelected}
            selectionCheck={(option, value) => option.label === value.label}
            usedFor="clipNameFilter"
            onChangeCall={(selectedOption) => {
                let userChoices = [];
                setChangesMade(true);
                selectedOption.forEach((chosen) => {
                    if (chosen.label !== "(All)") {
                        userChoices.push(chosen.label);
                    } else {
                        userChoices.push([...new Set(clipDetails.clip_names_list)]);
                    }
                });
                setClipSelection(userChoices);
                setClipNameOptionsSelected(selectedOption);
                // filterSingleValue(fieldName, userChoices, vizId);
            }}
            onBlurCall={(e) => {
                if (clipSelection.length !== 0 && changesMade === true) {
                    filterSingleValue(fieldName, clipSelection, vizId);
                    setChangesMade(false);
                }
            }}
        />
    );
}

export default ClipNameFilter;